<template>
  <div class="content">
    <div class="login" v-if="login" >
      <div class="loginBox">
        <div class="title">欢迎来到数据中心</div>
        <div class="loginContent">
          <el-form :model="loginForm" class="loginForm" :rules="loginFormRules" ref="loginForms">
            <el-form-item label="" prop="user">
              <el-input v-model="loginForm.user" placeholder="请输入产品管理员账号"></el-input>
            </el-form-item>
            <el-form-item label="" prop="password">
              <el-input v-model="loginForm.password" type="password" placeholder="请输入密码"></el-input>
            </el-form-item>
            <el-form-item class="submitBtn">
              <el-button type="primary"  @click="onSubmit">开始进入</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',

  data() {
    return {
      showLoad: false,
      login:true,
      loginForm:{

      },
      loginFormRules:{
        user: [{
          required: true,
          message: '请填写管理员账号',
          trigger: 'bulr',
        }],
        password: [{
          required: true,
          message: '请填写密码',
          trigger: 'bulr',
        }],
      }
    }
  },
  mounted() {
    // this.setTime()
  },
  methods: {
    //设置定时器
    // setTime() {
    //   const _this = this
    //   setTimeout(function(){
    //     _this.showLoad = false
    //   },1100);
    // },
    onSubmit(){
      this.$refs['loginForms'].validate((valid) => {
        if (valid) {
          if(this.loginForm.user == 'user' && this.loginForm.password == '123'){
            // this.$router.push('/lsdc')
            this.$router.push('/lsmc')
          }
        }
      });

    }
    // 大屏

  }
}
</script>

<style scoped>
.login{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /*background: url("../../assets/bg2.jpg");*/
  background-size: cover;
}
.loginBox{
  width: 5.6rem;
  height: 3.4rem;
  background: #00053E;
  font-size: 0.24rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 10px 0px rgba(10, 127, 218, 0.2);
  border-radius: 10px;
}
.title{
  width: 100%;
  height: 0.6rem;
  background: #1347C6;
  line-height: 0.6rem;
  text-align: center;
  font-size: 0.24rem;
  font-family: 'Microsoft YaHei';
  font-weight: 400;
  color: #FFFFFF;
  /*line-height: 0.21rem;*/
}
.loginContent{
  width: 60%;
  margin-top: 0.4rem;
  height: 80%;
  /*display: flex;*/
  /*justify-content: space-between;*/
  /*align-items: center;*/
}
.loginForm /deep/.el-input__inner {
  margin: 0.1rem 0;
  background-color: #0B1375;
  border: 1px solid #0B1375;
  color:#fff;
}
.submitBtn{
  text-align: center;
}
.submitBtn /deep/.el-button--primary {
  color: #FFF;
  background-color: #1347C6;
  border-color: #1347C6;
  width: 1.4rem;
  height: 0.48rem;
}
/*样式*/
.content {
  width: 100%;
  height: 100%;
  background: url("../../assets/lsdcBg.png") no-repeat;
  background-size: 100% 100%;
}
/* 加载旋转动画 */
#load{width:100%;height:100%;position:absolute;background:url(../../assets/lsdcBg.png) no-repeat #061537;background-size:cover;top:0;left:0;z-index:999}
#load .load_img{position:absolute;left:calc(50% - 182px);top:calc(50% - 182px);}
.load_img img{position:absolute;left:0;top:0;}
.load_img .jzxz1{animation:xz1 8s infinite linear;}
@keyframes xz1 {
  from {
    transform:rotate(0deg);
  }
  50% {
    transform:rotate(180deg);
  }
  to{
    transform:rotate(360deg);
  }
}
.load_img .jzxz2{animation:xz2 7s infinite linear;}
@keyframes xz2 {
  from {
    transform:rotate(0deg);
  }
  50% {
    transform:rotate(-180deg);
  }
  to{
    transform:rotate(-360deg);
  }
}
</style>

